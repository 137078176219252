<template>
  <div class="pa-5 content-db-style">
    <!-- <v-row class="mb-1">
      <v-col cols="6" align-start>
        <v-btn depressed color="white" :disabled="loading" @click="initialize()"><v-icon>mdi-reload</v-icon></v-btn>
      </v-col>
      <v-col cols="6" align="right">
        <v-autocomplete
        v-model="dateRange"
        dense
        style="max-width: 230px;"
        item-text="description"
        item-value="id"
        :items="filters"
        item-color="#38435a"
        height="40"
        hide-details
        solo-inverted
        flat
        :disabled="ac_disabled"
        color="#38435a"
        @change="selectDateRange()"
        @click="clickDateRange()"
        >
        <template slot="selection" slot-scope="{ item }">
            <span v-if="item.id == 7" class="text-no-wrap">{{displayCustomDateText(item.id)}}</span>
            <span v-else class="text-no-wrap">{{displayDateText(item.id)}}</span>
        </template>
        </v-autocomplete>
    <v-menu
      v-if="showDatePicker"
      ref="menu1"
      dense
      v-model="datePickerMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      class="mb-4"
      min-width="auto"
    >
      <v-date-picker
        v-model="selectedDate"
        range
        scrollable
        color="#38435a"
      >
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="datePickerMenu = false">
          close
        </v-btn>
        <v-btn
          color="#38435a"
          @click="saveDate(selectedDate)"
          dark
          class="elevation-0"
        >
          Apply
        </v-btn>
      </v-date-picker>
    </v-menu>
      </v-col>
    </v-row> -->
    <v-card class="card-style rounded-1">
    <v-card-title class="pa-0">
       <v-row no-gutters>
      <v-col cols="12" sm="6" :align="currentSize >= 2 ? 'left' : 'center'" class="px-5 py-2">
        <h4 class="mt-1">TOTAL</h4>
      </v-col>
      <v-col cols="12" sm="6" :align="currentSize >= 2 ? 'right' : 'center'" class="px-5 py-2">
          <div class="drp-div-style">
            <date-range-picker class="drp-style" v-model="dateRange" format="MMM D, YYYY" :options="dr_options" />
            <v-icon class="pa-0">mdi-chevron-down</v-icon>
          </div>
        <!-- <v-autocomplete
        v-model="dateRange"
        dense
        style="max-width: 230px;"
        item-text="description"
        item-value="id"
        :items="filters"
        item-color="#38435a"
        height="40"
        hide-details
        solo-inverted
        flat
        :disabled="ac_disabled"
        color="#38435a"
        @change="selectDateRange()"
        @click="clickDateRange()"
        >
        <template slot="selection" slot-scope="{ item }">
            <span v-if="item.id == 7" class="text-no-wrap">{{displayCustomDateText(item.id)}}</span>
            <span v-else class="text-no-wrap">{{displayDateText(item.id)}}</span>
        </template>
        </v-autocomplete> -->
    <v-menu
      v-if="showDatePicker"
      ref="menu1"
      dense
      v-model="datePickerMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      class="mb-4"
      min-width="auto"
    >
      <v-date-picker
        v-model="selectedDate"
        range
        scrollable
        color="#38435a"
      >
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="datePickerMenu = false">
          close
        </v-btn>
        <v-btn
          color="#38435a"
          @click="saveDate(selectedDate)"
          dark
          class="elevation-0"
        >
          Apply
        </v-btn>
      </v-date-picker>
    </v-menu>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    </v-card-title>
    <v-row class="ma-2">
      <!-- <v-col cols="12" class="flex-items">
        <h3>TOTAL</h3>
      </v-col> -->
      <v-col cols="12">
        <v-row class="stats-style">
          <v-col cols="12" sm="6" md="3">
            <!--START MEDIA-->
            <div class="my-3">
              <h5>NET PROFIT (excl AD COST)</h5>
              <h2>{{formatMoney(data.new_method_total_profit - (data.redtrack_stats.cost || 0))}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_total_profit - (data.redtrack_stats.cost || 0))-( data.new_method_total_profit_compared - (data.redtrack_stats_compared.cost || 0))) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_profit - (data.redtrack_stats.cost || 0), data.new_method_total_profit_compared - (data.redtrack_stats_compared.cost || 0))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_profit - (data.redtrack_stats.cost || 0), data.new_method_total_profit_compared - (data.redtrack_stats_compared.cost || 0))}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_profit_compared - (data.redtrack_stats_compared.cost || 0)) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_total_profit - (data.redtrack_stats.cost || 0))-(data.new_method_total_profit_compared_st - (data.redtrack_stats_compared_st.cost || 0))) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange((data.new_method_total_profit - (data.redtrack_stats.cost || 0))-(data.new_method_total_profit_compared_st - (data.redtrack_stats_compared_st.cost || 0)))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_total_profit - (data.redtrack_stats.cost || 0), data.new_method_total_profit_compared - (data.redtrack_stats_compared.cost || 0))}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.new_method_total_profit_compared_st - (data.redtrack_stats_compared_st.cost || 0))+ " " + data.compared_wording +  " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->

             <!--START MEDIA-->
            <div class="my-3">
              <h5>PROFIT</h5>
              <h2>{{formatMoney(data.new_method_total_profit)}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.profit_amount_compared) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.profit_amount_compared_esta_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>USA-ESTA</h5>
              <h4>{{formatMoney(data.profit_amount_esta)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_esta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_esta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_esta_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.profit_amount_compared_esta) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_esta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_esta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_esta_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.profit_amount_compared_esta_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>K-ETA</h5>
              <h4>{{formatMoney(data.profit_amount_keta)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_keta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_keta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_keta_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.profit_amount_compared_keta) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_keta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_keta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_keta_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.profit_amount_compared_keta_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>UK-EVW</h5>
              <h4>{{formatMoney(data.profit_amount_evw)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_evw_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_evw_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_evw_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.profit_amount_compared_evw) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.profit_amount_compared_evw_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.profit_amount_compared_evw_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.profit_amount_compared_evw_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.profit_amount_compared_evw_st) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>KH-EVISA</h5>
              <h4>{{formatMoney(data.new_method_profit_kh_evisa)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_profit_kh_evisa - data.new_method_profit_compared_kh_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa,data.new_method_profit_compared_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa,data.new_method_profit_compared_kh_evisa)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_profit_compared_kh_evisa) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_profit_kh_evisa)-(data.new_method_profit_compared_st_kh_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa, data.new_method_profit_compared_st_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_kh_evisa, data.new_method_profit_compared_st_kh_evisa)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.new_method_profit_compared_st_kh_evisa) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>VN-EVISA</h5>
              <h4>{{formatMoney(data.new_method_profit_vn_evisa)}}</h4>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_profit_vn_evisa - data.new_method_profit_compared_vn_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa,data.new_method_profit_compared_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa,data.new_method_profit_compared_vn_evisa)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_profit_compared_vn_evisa) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_profit_vn_evisa)-(data.new_method_profit_compared_st_vn_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa, data.new_method_profit_compared_st_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_profit_vn_evisa, data.new_method_profit_compared_st_vn_evisa)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.new_method_profit_compared_st_vn_evisa) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" sm="6" md="3">
            <!--START MEDIA-->
            <div class="my-3">
              <h5>TOTAL REVENUE</h5>
              <h2>{{formatMoney(data.earned)}}</h2>
              <div>
                <span class="chip-text">{{data.new_method_total_applications_processed_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{data.new_method_total_applications_processed_compared_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
                <span class="chip-text">{{data.new_method_total_applications_processed_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{(data.applications_processed_compared_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>USA-ESTA</h5>
              <h4>{{formatMoney(data.earned_esta)}}</h4>
              <div>
                <span class="chip-text">{{data.applications_processed_esta_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_esta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_esta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_esta_change}}%</span>
              </v-chip>

              <span class="chip-text">{{data.applications_processed_compared_esta_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text">{{data.applications_processed_esta_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_esta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_esta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_esta_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{(data.applications_processed_compared_esta_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>K-ETA</h5>
              <h4>{{formatMoney(data.earned_keta)}}</h4>
              <div>
                <span class="chip-text">{{data.applications_processed_keta_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_keta_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_keta_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_keta_change}}%</span>
              </v-chip>

              <span class="chip-text">{{data.applications_processed_compared_keta_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text">{{data.applications_processed_keta_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_keta_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_keta_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_keta_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{(data.applications_processed_compared_keta_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>UK-EVW</h5>
              <h4>{{formatMoney(data.earned_evw)}}</h4>
              <div>
                <span class="chip-text">{{data.applications_processed_evw_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_evw_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_evw_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_evw_change}}%</span>
              </v-chip>

              <span class="chip-text">{{data.applications_processed_compared_evw_count + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text">{{data.applications_processed_evw_count + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.applications_processed_compared_evw_change_trend_st == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.applications_processed_compared_evw_change_st}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.applications_processed_compared_evw_change_st}}%</span>
              </v-chip>
              <span class="chip-text">{{(data.applications_processed_compared_evw_count_st || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>KH-EVISA</h5>
              <h4>{{formatMoney(data.new_method_earned_kh_evisa)}}</h4>
              <div>
                <span class="chip-text">{{data.new_method_applications_processed_count_kh_evisa + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_applications_processed_count_kh_evisa - data.new_method_applications_processed_compared_count_kh_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa,data.new_method_applications_processed_compared_count_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa,data.new_method_applications_processed_compared_count_kh_evisa)}}%</span>
              </v-chip>

              <span class="chip-text">{{data.new_method_applications_processed_compared_count_kh_evisa + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text">{{data.new_method_applications_processed_count_kh_evisa + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_applications_processed_count_kh_evisa)-(data.new_method_applications_processed_compared_count_st_kh_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa, data.new_method_applications_processed_compared_count_st_kh_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_kh_evisa, data.new_method_applications_processed_compared_count_st_kh_evisa)}}%</span>
              </v-chip>
              <span class="chip-text">{{data.new_method_applications_processed_compared_count_st_kh_evisa + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->


            <!--START SUB MEDIA-->
            <div class="my-3 ml-4">
              <h5>VN-EVISA</h5>
              <h4>{{formatMoney(data.new_method_earned_vn_evisa)}}</h4>
              <div>
                <span class="chip-text">{{data.new_method_applications_processed_count_vn_evisa + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(data.new_method_applications_processed_count_vn_evisa - data.new_method_applications_processed_compared_count_vn_evisa) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa,data.new_method_applications_processed_compared_count_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa,data.new_method_applications_processed_compared_count_vn_evisa)}}%</span>
              </v-chip>

              <span class="chip-text">{{data.new_method_applications_processed_compared_count_vn_evisa + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <span class="chip-text">{{data.new_method_applications_processed_count_vn_evisa + " sales"}}</span>
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.new_method_applications_processed_count_vn_evisa)-(data.new_method_applications_processed_compared_count_st_vn_evisa)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa, data.new_method_applications_processed_compared_count_st_vn_evisa)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.new_method_applications_processed_count_vn_evisa, data.new_method_applications_processed_compared_count_st_vn_evisa)}}%</span>
              </v-chip>
              <span class="chip-text">{{data.new_method_applications_processed_compared_count_st_vn_evisa + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END SUB MEDIA-->
            
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" sm="6" md="3">

            <!--START MEDIA-->
            <div class="my-3">
              <h5>REDTRACK STATS</h5>
              <h5 class="mt-3">AD COST</h5>
              <h2>{{formatMoney(data.redtrack_stats.cost || 0)}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.redtrack_stats.cost || 0)-(data.redtrack_stats_compared.cost || 0)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.cost || 0, data.redtrack_stats_compared.cost || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.cost || 0, data.redtrack_stats_compared.cost || 0)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.redtrack_stats_compared.cost || 0) + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.redtrack_stats.cost || 0)-(data.redtrack_stats_compared_st.cost || 0)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.cost || 0, data.redtrack_stats_compared_st.cost || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.cost || 0, data.redtrack_stats_compared_st.cost || 0)}}%</span>
              </v-chip>
              <span class="chip-text">{{formatMoney(data.redtrack_stats_compared_st.cost || 0) + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="my-3">
              <h5 class="mt-3">CLICKS</h5>
              <h2>{{(data.redtrack_stats.clicks || 0).toLocaleString()}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.redtrack_stats.clicks || 0)-(data.redtrack_stats_compared.clicks || 0)) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.clicks || 0, data.redtrack_stats_compared.clicks || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.clicks || 0, data.redtrack_stats_compared.clicks || 0)}}%</span>
              </v-chip>

              <span class="chip-text">{{(data.redtrack_stats_compared.clicks || 0).toLocaleString() + " " + data.compared_wording}}</span>
              </div>
              <div v-if="data.same_time_comparison">
              <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((data.redtrack_stats.clicks || 0)-(data.redtrack_stats_compared_st.clicks || 0)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.clicks || 0, data.redtrack_stats_compared_st.clicks || 0)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(data.redtrack_stats.clicks || 0, data.redtrack_stats_compared_st.clicks || 0)}}%</span>
              </v-chip>
              <span class="chip-text">{{(data.redtrack_stats_compared_st.clicks || 0).toLocaleString() + " " + data.compared_wording + " " + "@" + data.check_time }}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="mt-3 flex-items">
              <h5 class="mr-3">AVG CPC</h5>
              <div>
                <span class="chip-text">{{formatMoney(data.redtrack_stats.cpc || 0)}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(((data.redtrack_stats.cpc || 0) * 100) - ((data.redtrack_stats_compared.cpc || 0) * 100)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange((data.redtrack_stats.cpc || 0) * 100, (data.redtrack_stats_compared.cpc || 0) * 100)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange((data.redtrack_stats.cpc || 0) * 100, (data.redtrack_stats_compared.cpc || 0) * 100)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.redtrack_stats_compared.cpc || 0)}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">CR</h5>
              <div>
                <span class="chip-text">{{(((data.applications_processed_count / (data.redtrack_stats.clicks || 0)) || 0) * 100).toFixed(2) + '%'}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend((10000 * data.applications_processed_count / (data.redtrack_stats.clicks || 0))-(10000 * data.applications_processed_compared_count/ (data.redtrack_stats_compared.clicks || 0))) == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(10000*data.applications_processed_count / (data.redtrack_stats.clicks || 0), 10000 * data.applications_processed_compared_count/ (data.redtrack_stats_compared.clicks || 0))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(10000*data.applications_processed_count / (data.redtrack_stats.clicks || 0), 10000 * data.applications_processed_compared_count/ (data.redtrack_stats_compared.clicks || 0))}}%</span>
              </v-chip>

              <span class="chip-text">{{(((data.applications_processed_count / (data.redtrack_stats_compared.clicks || 0)) || 0) * 100).toFixed(2) + '%'}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">EPC</h5>
              <div>
                <span class="chip-text">{{formatMoney(data.new_method_total_earned / (data.redtrack_stats.clicks || 0))}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(((data.redtrack_stats.cpc || 0) * 100) - ((data.redtrack_stats_compared.cpc || 0) * 100)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(100 * data.new_method_total_earned / (data.redtrack_stats.clicks || 0), 100 * data.new_method_total_earned_compared / (data.redtrack_stats_compared.clicks || 0))}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(100 * data.new_method_total_earned / (data.redtrack_stats.clicks || 0), 100 * data.new_method_total_earned_compared / (data.redtrack_stats_compared.clicks || 0))}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_earned_compared / (data.redtrack_stats_compared.clicks || 0))}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="flex-items">
              <h5 class="mr-3">CPA</h5>
              <div>
                <span class="chip-text">{{formatMoney((data.redtrack_stats.cost || 0) / data.applications_processed_count)}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-if="getStatsComparedTrend(((data.redtrack_stats.cpc || 0) * 100) - ((data.redtrack_stats_compared.cpc || 0) * 100)) == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{getStatsComparedChange(((data.redtrack_stats.cost || 0) / data.applications_processed_count), (data.redtrack_stats_compared.cost || 0) / data.applications_processed_compared_count)}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mx-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{getStatsComparedChange(((data.redtrack_stats.cost || 0) / data.applications_processed_count), (data.redtrack_stats_compared.cost || 0) / data.applications_processed_compared_count)}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney((data.redtrack_stats_compared.cost || 0) / data.applications_processed_compared_count)}}</span>
              </div>
            </div>
            <!--END MEDIA-->
            
            <!--START MEDIA-->
            <div class="my-4">
              <h5 class="mr-3">APPLICATIONS QUEUE</h5>
              <v-row class="chip-text mt-1" no-gutters>
                <v-col cols="8">available</v-col>
                <v-col cols="4" align="end" class="pr-7">{{data.count_available_applications}}</v-col>

                <v-col cols="8">pending passport</v-col>
                <v-col cols="4" align="end" class="pr-7">{{data.count_pending_passport}}</v-col>
 
                <v-col cols="8">processing</v-col>
                <v-col cols="4" align="end" class="pr-7">{{data.count_processing}}</v-col>

                <v-col cols="8">awaiting response</v-col>
                <v-col cols="4" align="end" class="pr-7">{{data.count_awaiting_response}}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
                <v-col cols="8"><h6 class="chip-text">TOTAL FOR SUBMISSION</h6></v-col>
                <v-col cols="4" align="end" class="pr-7">{{(parseInt(data.count_available_applications) + parseInt(data.count_pending_passport) + parseInt(data.count_processing) + parseInt(data.count_awaiting_response))}}</v-col>
              </v-row>
            </div>
            <!--END MEDIA-->
          </v-col>
            <v-divider class="divider-style"
              vertical
            ></v-divider>
          <v-col cols="12" sm="6" md="3">
            <!--START MEDIA-->
            <div class="my-3">
              <h5>OPERATING EXPENSES</h5>
              <h2>{{formatMoney(data.new_method_total_gov_fees + data.new_method_total_transaction_fees)}}</h2>
              <div>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.gov_fees_amount_compared_change_trend == 'green'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.gov_fees_amount_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.gov_fees_amount_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{formatMoney(data.new_method_total_gov_fees_compared + data.new_method_total_transaction_fees_compared) + " " + data.compared_wording}}</span>
              </div>
            </div>
            <!--END MEDIA-->

            <!--START MEDIA-->
            <div class="my-3">
              <h5>AFFILIATE COST</h5>
              <h2>{{formatMoney(data.aff_earned || 0)}}</h2>
              <div>
                <span class="chip-text">{{data.aff_applications_processed_count + " sales"}}</span>
                <v-chip
                class="chip-status-2 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-if="data.aff_applications_processed_compared_change_trend == 'red'"
              >
              <v-icon small color="#e12d2f">mdi-chart-ppf</v-icon>
              <span>{{data.aff_earned_compared_change}}%</span>
              </v-chip>
              <v-chip
                class="chip-status-1 rounded py-0 px-1 mr-2 my-1 font-weight-bold text-wrap"
                small
                v-else
              >
              <v-icon small color="#01c9a8">mdi-chart-bell-curve-cumulative</v-icon>
              <span>{{data.aff_earned_compared_change}}%</span>
              </v-chip>

              <span class="chip-text">{{data.aff_applications_processed_compared_count + " " + data.compared_wording}}</span>
              </div>
            </div>
            <!--END MEDIA-->
            
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>
    </v-card>
    <v-card class="card-style rounded-1 my-5" >
    <v-row class="ma-2">
      <v-col cols="12" class="flex-items">
        <h4>Stripe Distribution ({{active_stripe_accounts_count}} active)</h4>
      </v-col>
    </v-row>
    <v-card class="mb-5 dt-container" elevation="0" outlined>
    <v-data-table
      :loading="loading"
       :headers="headers"
        :items="loading ? [] : data.stripe_stats"
        item-key="id"
        :options.sync="options"
        disable-pagination
        hide-default-footer
    >
    <template v-slot:[`item.account`]="{ item }">
      <span class="text-no-wrap font-weight-bold">
        <span class="mr-2">{{item.mid.replace("stripe_", "")}}</span>
          <v-chip class="chip-status-3 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>Stripe</span>
          </v-chip>
          <v-chip v-if="item.last_10_intents == 10" class="chip-status-4 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>{{((item.last_10_paid / item.last_10_intents)*100).toFixed(0) + '%'}}</span>
          </v-chip>
          <v-chip v-else class="chip-status-4 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>{{item.last_10_paid + " / " + item.last_10_intents}}</span>
          </v-chip>
      </span>
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      <span class="text-no-wrap font-weight-bold">
        <span class="mr-2">{{item.total_amount_formatted}}</span>
      </span>
    </template>
    <template v-slot:[`item.settings`]="{ item }">
      <span class="text-no-wrap font-weight-bold" v-if="item.settings !== null">
        <span v-if="item.revenue_limit == 0">
          <v-chip class="chip-status-5 rounded mr-2 my-1 font-weight-bold text-wrap" small>
            <span>DISABLED</span>
          </v-chip>
        </span>
        <span v-else>
          <span v-if="item.all_products == 1">
            <v-chip class="chip-status-1 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>ALL PRODUCTS</span>
            </v-chip>
          </span>
          <span v-else>
            {{item.settings}} <br>
          </span>

          <span v-if="item.revenue_limit > -1">
            <span v-if="item.revenue_limit_reached == 1">
              <v-chip class="chip-status-3 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>[max_revenue = {{ parseFloat(item.revenue_limit).toLocaleString() }} REACHED]</span>
            </v-chip>
            </span>
            <span v-else>
              <v-chip class="chip-status-4 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>[max_revenue = {{ parseFloat(item.revenue_limit).toLocaleString() }}]</span>
            </v-chip>
            </span>
          </span>
          <span v-else>
            <v-chip class="chip-status-1 rounded mr-2 my-1 font-weight-bold text-wrap" small>
              <span>[unlimited revenue]</span>
            </v-chip>
          </span>

          <span v-if="item.instant_payout_limit > 0">
            <span v-if="item.instant_payout_available >0">
              <v-chip class="chip-status-6 rounded mr-2 my-1 font-weight-bold text-wrap" small>
                <span>[instant_payout_limit = {{parseFloat(item.instant_payout_limit).toLocaleString()}}]</span>
              </v-chip>
            </span>
            <span v-else>
              <v-chip class="chip-status-5 rounded mr-2 my-1 font-weight-bold text-wrap" small>
                <span>[instant_payout_limit = {{ parseFloat(item.stripe_stats.instant_payout_limit).toLocaleString() }} REACHED]</span>
              </v-chip>
            </span>
          </span>
        </span>
      </span>
      <span v-else>
        <v-chip class="chip-status-5 rounded mr-2 my-1 font-weight-bold text-wrap" small>
          <span>DISABLED</span>
        </v-chip>
      </span>
    </template>
    </v-data-table>
    </v-card>
    </v-card>
    <v-overlay
      :absolute="true"
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="74"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showDatePicker2" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h6">
          Custom Range Date
        </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center" class="my-5">
      <date-picker v-model="selectedDate2" type="date" range placeholder="Select date range"></date-picker>
      </v-row>
      </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LineChartDown from '../../assets/icon/line-chart-down.svg'
import LineChartUp from '../../assets/icon/line-chart-up.svg'
import moment from "moment";
  export default {
    componenents: {
      LineChartDown,
      LineChartUp,
    },
    data: () => ({
        mini: false,
        filters: [
          {id: 1, description: 'Today'},
          {id: 2, description: 'Yesterday'},
          {id: 3, description: 'Last 7 Days'},
          {id: 4, description: 'Last 30 Days'},
          {id: 5, description: 'This Month'},
          {id: 6, description: 'Last Month'},
          {id: 7, description: 'Custom Range'},
        ],
        startDate: null,
        endDate: null,
        //dateRange: 1,
        dateSelectedText: null,
        search: '',
        headers: [
            { text: 'ACCOUNT', value: 'account' },
            { text: 'AMOUNT', value: 'amount', align: 'right'},
            { text: 'SETTINGS', value: 'settings'},
          ],
        data: {
            "compared_wording": "...",
            "debug_total": "",
            "debug_keta": "",
            "debug_esta": "",
            "applications_processed_count": 0,
            "applications_processed_keta_count": 0,
            "applications_processed_esta_count": 0,
            "applications_processed_evw_count": 0,
            "applications_processed_compared_count": 0,
            "applications_processed_compared_keta_count": 0,
            "applications_processed_compared_esta_count": 0,
            "applications_processed_compared_evw_count": 0,
            "applications_processed_compared_change": "0.00",
            "applications_processed_compared_change_trend": "red",
            "applications_processed_compared_keta_change": "0.00",
            "applications_processed_compared_keta_change_trend": "red",
            "applications_processed_compared_esta_change": "0.00",
            "applications_processed_compared_esta_change_trend": "red",
            "applications_processed_compared_evw_change": "0.00",
            "applications_processed_compared_evw_change_trend": "red",
            "earned": "0.00",
            "earned_esta": "0.00",
            "earned_keta": "0.00",
            "earned_evw": "0.00",
            "earned_compared": "0.00",
            "earned_compared_keta": "0.00",
            "earned_compared_esta": "0.00",
            "earned_compared_evw": "0.00",
            "earned_compared_change": "0.00",
            "earned_compared_change_trend": "red",
            "aff_applications_processed_count": 0,
            "aff_applications_processed_count_esta": 0,
            "aff_applications_processed_count_keta": 0,
            "aff_applications_processed_count_evw": 0,
            "aff_applications_processed_compared_count": 0,
            "aff_applications_processed_compared_keta_count": 0,
            "aff_applications_processed_compared_esta_count": 0,
            "aff_applications_processed_compared_evw_count": 0,
            "aff_applications_processed_compared_change": "0.00",
            "aff_applications_processed_compared_change_trend": "red",
            "aff_applications_processed_compared_keta_change": "0.00",
            "aff_applications_processed_compared_keta_change_trend": "red",
            "aff_applications_processed_compared_esta_change": "0.00",
            "aff_applications_processed_compared_esta_change_trend": "red",
            "aff_applications_processed_compared_evw_change": "0.00",
            "aff_applications_processed_compared_evw_change_trend": "red",
            "aff_earned_compared_change": "0.00",
            "aff_earned_compared_change_trend": "red",
            "aff_earned_compared_keta_change": "0.00",
            "aff_earned_compared_keta_change_trend": "red",
            "aff_earned_compared_esta_change": "0.00",
            "aff_earned_compared_esta_change_trend": "red",
            "aff_earned_compared_evw_change": "0.00",
            "aff_earned_compared_evw_change_trend": "red",
            "gov_fees_amount": "0.00",
            "gov_fees_amount_esta": "0.00",
            "gov_fees_amount_keta": "0.00",
            "gov_fees_amount_evw": "0.00",
            "gov_fees_amount_compared": "0.00",
            "gov_fees_amount_compared_change": "0.00",
            "gov_fees_amount_compared_change_trend": "red",
            "gov_fees_amount_compared_keta": "0.00",
            "gov_fees_amount_compared_keta_change": "0.00",
            "gov_fees_amount_compared_keta_change_trend": "red",
            "gov_fees_amount_compared_esta": "0.00",
            "gov_fees_amount_compared_esta_change": "0.00",
            "gov_fees_amount_compared_esta_change_trend": "red",
            "gov_fees_amount_compared_evw": "0.00",
            "gov_fees_amount_compared_evw_change": "0.00",
            "gov_fees_amount_compared_evw_change_trend": "red",
            "profit_amount": "0.00",
            "profit_amount_esta": "0.00",
            "profit_amount_keta": "0.00",
            "profit_amount_evw": "0.00",
            "profit_amount_compared": "0.00",
            "profit_amount_compared_change": "0.00",
            "profit_amount_compared_change_trend": "red",
            "profit_amount_compared_keta": "0.00",
            "profit_amount_compared_keta_change": "0.00",
            "profit_amount_compared_keta_change_trend": "red",
            "profit_amount_compared_esta": "0.00",
            "profit_amount_compared_esta_change": "0.00",
            "profit_amount_compared_esta_change_trend": "red",
            "profit_amount_compared_evw": "0.00",
            "profit_amount_compared_evw_change": "0.00",
            "profit_amount_compared_evw_change_trend": "red",
            "same_time_comparison": true,
            "stats": {
                "applications_processed_compared_count_st": "0",
                "applications_processed_compared_esta_count_st": "0",
                "applications_processed_compared_keta_count_st": "0",
                "applications_processed_compared_evw_count_st": "0",
                "earned_compared_st": null,
                "earned_compared_esta_st": null,
                "earned_compared_keta_st": null,
                "earned_compared_evw_st": null,
                "aff_applications_processed_compared_count_st": "0",
                "aff_applications_processed_compared_esta_count_st": "0",
                "aff_applications_processed_compared_keta_count_st": "0",
                "aff_applications_processed_compared_evw_count_st": "0",
                "aff_earned_compared_st": 0,
                "aff_earned_compared_esta_st": 0,
                "aff_earned_compared_keta_st": 0,
                "aff_earned_compared_evw_st": 0,
                "transaction_fees_compared_st": null,
                "transaction_fees_compared_esta_st": null,
                "transaction_fees_compared_keta_st": null,
                "transaction_fees_compared_evw_st": null
            },
            "stats_sql": "",
            "check_time": "...",
            "gov_fees_amount_compared_st": null,
            "gov_fees_amount_compared_esta_st": null,
            "gov_fees_amount_compared_keta_st": null,
            "gov_fees_amount_compared_evw_st": null,
            "profit_amount_compared_st": "0",
            "profit_amount_compared_esta_st": "0",
            "profit_amount_compared_keta_st": "0",
            "profit_amount_compared_evw_st": "0",
            "profit_amount_compared_change_st": "0",
            "profit_amount_compared_change_trend_st": "red",
            "profit_amount_compared_esta_change_st": "0",
            "profit_amount_compared_esta_change_trend_st": "red",
            "profit_amount_compared_keta_change_st": "0",
            "profit_amount_compared_keta_change_trend_st": "red",
            "profit_amount_compared_evw_change_st": "0",
            "profit_amount_compared_evw_change_trend_st": "red",
            "applications_processed_compared_change_st": "0",
            "applications_processed_compared_change_trend_st": "red",
            "applications_processed_compared_keta_change_st": "0",
            "applications_processed_compared_keta_change_trend_st": "red",
            "applications_processed_compared_esta_change_st": "0",
            "applications_processed_compared_esta_change_trend_st": "red",
            "applications_processed_compared_evw_change_st": "0",
            "applications_processed_compared_evw_change_trend_st": "red",
            "redtrack_stats_compared_st": {
                "id": null,
                "cost": 0,
                "clicks": 0,
                "conversions": 0,
                "updated_at_timestamp": ""
            },
            "new_method_total_app_type": 0,
            "new_method_app_type_esta": "USA-ESTA",
            "new_method_total_applications_processed_count": 0,
            "new_method_applications_processed_count_esta": "0",
            "new_method_total_applications_processed_compared_count": 0,
            "new_method_applications_processed_compared_count_esta": "0",
            "new_method_total_applications_processed_compared_count_st": 0,
            "new_method_applications_processed_compared_count_st_esta": "0",
            "new_method_total_earned": 0,
            "new_method_earned_esta": 0,
            "new_method_total_earned_compared": 0,
            "new_method_earned_compared_esta": 0,
            "new_method_total_earned_compared_st": 0,
            "new_method_earned_compared_st_esta": 0,
            "new_method_total_aff_applications_processed_count": 0,
            "new_method_aff_applications_processed_count_esta": "0",
            "new_method_total_aff_applications_processed_compared_count": 0,
            "new_method_aff_applications_processed_compared_count_esta": "0",
            "new_method_total_aff_applications_processed_compared_count_st": 0,
            "new_method_aff_applications_processed_compared_count_st_esta": "0",
            "new_method_total_aff_earned": 0,
            "new_method_aff_earned_esta": 0,
            "new_method_total_aff_earned_compared": 0,
            "new_method_aff_earned_compared_esta": 0,
            "new_method_total_aff_earned_compared_st": 0,
            "new_method_aff_earned_compared_st_esta": 0,
            "new_method_total_transaction_fees": 0,
            "new_method_transaction_fees_esta": 0,
            "new_method_total_transaction_fees_compared": 0,
            "new_method_transaction_fees_compared_esta": 0,
            "new_method_total_transaction_fees_compared_st": 0,
            "new_method_transaction_fees_compared_st_esta": 0,
            "new_method_total_gov_fees": 0,
            "new_method_gov_fees_esta": "0",
            "new_method_total_gov_fees_compared": 0,
            "new_method_gov_fees_compared_esta": "0",
            "new_method_total_gov_fees_compared_st": 0,
            "new_method_gov_fees_compared_st_esta": "0",
            "new_method_total_profit": 0,
            "new_method_profit_esta": 0,
            "new_method_total_profit_compared": 0,
            "new_method_profit_compared_esta": 0,
            "new_method_total_profit_compared_st": 0,
            "new_method_profit_compared_st_esta": 0,
            "new_method_total_stats_timeframe": 0,
            "new_method_stats_timeframe_esta": "",
            "new_method_total_compared_timeframe": 0,
            "new_method_compared_timeframe_esta": "",
            "new_method_total_compared_timeframe_st": 0,
            "new_method_compared_timeframe_st_esta": "",
            "new_method_total_now_timestamp_db": 0,
            "new_method_now_timestamp_db_esta": "",
            "new_method_total_now_timestamp_server": 0,
            "new_method_now_timestamp_server_esta": "",
            "new_sql_USA-ESTA": "",
            "new_method_app_type_keta": "K-ETA",
            "new_method_applications_processed_count_keta": "0",
            "new_method_applications_processed_compared_count_keta": "0",
            "new_method_applications_processed_compared_count_st_keta": "0",
            "new_method_earned_keta": 0,
            "new_method_earned_compared_keta": 0,
            "new_method_earned_compared_st_keta": 0,
            "new_method_aff_applications_processed_count_keta": "0",
            "new_method_aff_applications_processed_compared_count_keta": "0",
            "new_method_aff_applications_processed_compared_count_st_keta": "0",
            "new_method_aff_earned_keta": 0,
            "new_method_aff_earned_compared_keta": 0,
            "new_method_aff_earned_compared_st_keta": 0,
            "new_method_transaction_fees_keta": 0,
            "new_method_transaction_fees_compared_keta": 0,
            "new_method_transaction_fees_compared_st_keta": 0,
            "new_method_gov_fees_keta": "0",
            "new_method_gov_fees_compared_keta": "0",
            "new_method_gov_fees_compared_st_keta": "0",
            "new_method_profit_keta": 0,
            "new_method_profit_compared_keta": 0,
            "new_method_profit_compared_st_keta": 0,
            "new_method_stats_timeframe_keta": "",
            "new_method_compared_timeframe_keta": "",
            "new_method_compared_timeframe_st_keta": "",
            "new_method_now_timestamp_db_keta": "",
            "new_method_now_timestamp_server_keta": "",
            "new_sql_K-ETA": "",
            "new_method_app_type_evw": "UK-EVW",
            "new_method_applications_processed_count_evw": "0",
            "new_method_applications_processed_compared_count_evw": "0",
            "new_method_applications_processed_compared_count_st_evw": "0",
            "new_method_earned_evw": 0,
            "new_method_earned_compared_evw": 0,
            "new_method_earned_compared_st_evw": 0,
            "new_method_aff_applications_processed_count_evw": "0",
            "new_method_aff_applications_processed_compared_count_evw": "0",
            "new_method_aff_applications_processed_compared_count_st_evw": "0",
            "new_method_aff_earned_evw": 0,
            "new_method_aff_earned_compared_evw": 0,
            "new_method_aff_earned_compared_st_evw": 0,
            "new_method_transaction_fees_evw": 0,
            "new_method_transaction_fees_compared_evw": 0,
            "new_method_transaction_fees_compared_st_evw": 0,
            "new_method_gov_fees_evw": "0",
            "new_method_gov_fees_compared_evw": "0",
            "new_method_gov_fees_compared_st_evw": "0",
            "new_method_profit_evw": 0,
            "new_method_profit_compared_evw": 0,
            "new_method_profit_compared_st_evw": 0,
            "new_method_stats_timeframe_evw": "",
            "new_method_compared_timeframe_evw": "",
            "new_method_compared_timeframe_st_evw": "",
            "new_method_now_timestamp_db_evw": "",
            "new_method_now_timestamp_server_evw": "",
            "new_sql_UK-EVW": "",
            "new_method_app_type_kh_evisa": "KH-EVISA",
            "new_method_applications_processed_count_kh_evisa": "0",
            "new_method_applications_processed_compared_count_kh_evisa": "0",
            "new_method_applications_processed_compared_count_st_kh_evisa": "0",
            "new_method_earned_kh_evisa": 0,
            "new_method_earned_compared_kh_evisa": 0,
            "new_method_earned_compared_st_kh_evisa": 0,
            "new_method_aff_applications_processed_count_kh_evisa": "0",
            "new_method_aff_applications_processed_compared_count_kh_evisa": "0",
            "new_method_aff_applications_processed_compared_count_st_kh_evisa": "0",
            "new_method_aff_earned_kh_evisa": 0,
            "new_method_aff_earned_compared_kh_evisa": 0,
            "new_method_aff_earned_compared_st_kh_evisa": 0,
            "new_method_transaction_fees_kh_evisa": 0,
            "new_method_transaction_fees_compared_kh_evisa": 0,
            "new_method_transaction_fees_compared_st_kh_evisa": 0,
            "new_method_gov_fees_kh_evisa": "0",
            "new_method_gov_fees_compared_kh_evisa": "0",
            "new_method_gov_fees_compared_st_kh_evisa": "0",
            "new_method_profit_kh_evisa": 0,
            "new_method_profit_compared_kh_evisa": 0,
            "new_method_profit_compared_st_kh_evisa": 0,
            "new_method_stats_timeframe_kh_evisa": "",
            "new_method_compared_timeframe_kh_evisa": "",
            "new_method_compared_timeframe_st_kh_evisa": "",
            "new_method_now_timestamp_db_kh_evisa": "",
            "new_method_now_timestamp_server_kh_evisa": "",
            "new_sql_KH-EVISA": "",
            "new_method_app_type_vn_evisa": "VN-EVISA",
            "new_method_applications_processed_count_vn_evisa": "0",
            "new_method_applications_processed_compared_count_vn_evisa": "0",
            "new_method_applications_processed_compared_count_st_vn_evisa": "0",
            "new_method_earned_vn_evisa": 0,
            "new_method_earned_compared_vn_evisa": 0,
            "new_method_earned_compared_st_vn_evisa": 0,
            "new_method_aff_applications_processed_count_vn_evisa": "0",
            "new_method_aff_applications_processed_compared_count_vn_evisa": "0",
            "new_method_aff_applications_processed_compared_count_st_vn_evisa": "0",
            "new_method_aff_earned_vn_evisa": 0,
            "new_method_aff_earned_compared_vn_evisa": 0,
            "new_method_aff_earned_compared_st_vn_evisa": 0,
            "new_method_transaction_fees_vn_evisa": 0,
            "new_method_transaction_fees_compared_vn_evisa": 0,
            "new_method_transaction_fees_compared_st_vn_evisa": 0,
            "new_method_gov_fees_vn_evisa": "0",
            "new_method_gov_fees_compared_vn_evisa": "0",
            "new_method_gov_fees_compared_st_vn_evisa": "0",
            "new_method_profit_vn_evisa": 0,
            "new_method_profit_compared_vn_evisa": 0,
            "new_method_profit_compared_st_vn_evisa": 0,
            "new_method_stats_timeframe_vn_evisa": "",
            "new_method_compared_timeframe_vn_evisa": "",
            "new_method_compared_timeframe_st_vn_evisa": "",
            "new_method_now_timestamp_db_vn_evisa": "",
            "new_method_now_timestamp_server_vn_evisa": "",
            "new_sql_VN-EVISA": "",
            "stripe_stats_sql": "",
            "stripe_stats": [],
            "count_available_applications": "0",
            "count_pending_passport": "0",
            "count_awaiting_response": "0",
            "count_processing": "0",
            "redtrack_stats": {},
            "redtrack_stats_compared": {}
        },
        perPageChoices: [
            {text:'5' , value: 5},
            {text:'10' , value: 10},
            {text:'20' , value: 20},
            {text:'50' , value: 50},
            {text:'100' , value: 100},
            {text:'250' , value: 250},
            {text:'500' , value: 500},
        ],
        options: {},
        paginationData: {},
        loading: false,
        active_stripe_accounts_count: 0,
        month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        showDatePicker: false,
        datePickerMenu: false,
        selectedDate: [],
        range_date_text: '',
        selectedDate2: [],
        showDatePicker2: false,
        ac_disabled: false,
        dateRange: [],
        dr_options: {
          ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          },
          "buttonClasses": "btn btn-lg",
          "applyButtonClasses": "btn-primary"
          }
    }),
    computed: {
      currentSize() {
        const breakpoint = this.$vuetify.breakpoint;
        if (breakpoint.xs) return 1;
        if (breakpoint.sm) return 2;
        if (breakpoint.md) return 3;
        if (breakpoint.lg) return 4;
        if (breakpoint.xl) return 5;
        return 0;
      }
    },
    watch: {
      dateRange: {
        handler(data){
          if(data.length == 2){
          var date1 = new Date(data[0]);
          this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
          var date2 = new Date(data[1]);
          this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
          this.initialize();
          }
        }
      }
    },
    methods: {
        initialize(){
          if(this.startDate && this.endDate){
            this.loading = true
          this.axiosCall('/dashboard/getStatistics?start_date=' + this.startDate + '&end_date=' + this.endDate, 'GET').then((res) => {
              if(res.data){
                this.loading = false
                this.data = res.data
                console.log(this.data)
                this.data.stripe_stats.forEach(item => {
                  if(item.settings !== null && item.revenue_limit > 0){
                    this.active_stripe_accounts_count += 1;
                  }
                });
              }
              else{
                this.showToast2("Something went wrong!", 'error')
              }
          })
          }
        },
    },
    mounted(){
        var date1 = new Date();
        this.startDate = parseInt(date1.getDate()) + '-' + parseInt(String(date1.getMonth() + 1).padStart(2, '0')) + '-' + date1.getFullYear();
        var date2 = new Date();
        this.endDate = parseInt(date2.getDate()) + '-' + parseInt(String(date2.getMonth() + 1).padStart(2, '0')) + '-' + date2.getFullYear();
        this.initialize();
    }
  }
</script>
<style scoped>
.chip-status-1{
  background-color: #e5f9f6 !important;
  color: #01c9a8 !important;
}
.chip-status-2{
  background-color: #fdf3f3 !important;
  color: #e12d2f !important;
}
.chip-status-3{
  background-color: #fbe996 !important;
  color: #413a29 !important;
}
.chip-status-4{
  background-color: #fefaf5 !important;
  color: #60b9cd !important;
}
.chip-status-5{
  background-color: #ebeef1 !important;
  color: #616f7e !important;
}
.stats-style h5{
  color: #8c98a4;
}
.stats-style .chip-text{
  color: #677788;
  font-weight: 600 ;
  font-size: .8125rem !important;
}
.divider-style{
  border-color: #eef0f7 !important;
}
.content-db-style{
  margin: 0 10%;
}
@media only screen and (max-width: 1200px) {
    .content-db-style{
      margin: 0;
    }
}
</style>